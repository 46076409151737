/* google font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');

/* layout */


body {
  background: #f1f1f1;
  margin: 0;
}

/* :root {
  --primary: #1aac83;
  --error: #e7195a;
}

body {
  background: #f1f1f1;
  margin: 0;
  font-family: "Poppins";
}


header a {
  color: #333;
  text-decoration: none;
} */